.product {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: #ECE9F5;
    border-radius: 20px;
    margin: 10px;
}

.product-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.product-code {
    font-weight: bold;
    font-size: 24px;
    color: #4F47A0;
    margin-bottom: 4px;
}

.product-title {
    font-weight: bold;
    font-size: 18px;
    color: #888;
    margin-bottom: 4px;
}

.product-name {
    font-weight: regular;
    font-size: 18px;
    color: #888;
    margin-bottom: 4px;
}

.product-prices {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.product-previous-price {
    font-size: 22px;
    color: #999;
    text-decoration: line-through;
    margin-bottom: 5px;
}

.product-price {
    font-size: 46px;
    color: #4F47A0;
}

.product-price sup {
    font-size: 24px;
}

.product.highlight {
    background-color: #510D75;
}
.product.highlight .product-code,
.product.highlight .product-title,
.product.highlight .product-name,
.product.highlight .product-price {
    color: #fff;
}
