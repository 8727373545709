.search-container {
    display: flex;
    align-items: center;
    background-color: #E2C8E7;
    border-radius: 20px;
    overflow: hidden;
    max-width: 320px;
    margin: 20px auto;
}

.search-input {
    flex-grow: 1;
    padding: 10px 15px;
    font-size: 1em;
    border: none;
    outline: none;
    background-color: #E2C8E7;
    color: #332781;
}

.search-button {
    padding: 10px 15px;
    background-color: #E2C8E7;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
}

.search-button:hover {
    background-color: #D6BFE1;
}
