body {
    font-family: Arial, sans-serif;
    background-color: #E2C8E7;
    line-height: 1.1;
}

header {
    background-color: #fff;
    padding: 10px;
    color: white;
    text-align: center;
}

.logo {
    font-size: 3em;
}

.subtitle {
    margin-top: 5px;
    font-weight: lighter;
}

.search {
    margin-top: 10px;
}

.search input[type="text"] {
    padding: 5px;
    border: none;
    color: #5a2b9a;
}

.campaign {
    padding: 10px;
    text-align: center;
    color: #838383;
    font-weight: bold;
    font-size: 20px;
}

.products {
    display: flex;
    height: calc(100vh - 328px);
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.products-title {
    text-align: center;
    margin: auto;
}

.page-number {
    color: #5a2b9a;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    padding: 10px 0;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.separator {
    border: none;
    height: 1px;
    background-color: #fff;
    margin: 60px 15px 20px;
}

.fullscreen-container {
    background-color: #fff;
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fullscreen-container .message {
    font-size: 22px;
    line-height: 1.5;
    color: #666;
    max-width: 360px;
    text-align: center;
    margin-top: 20px;
}
