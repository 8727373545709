.numberStepperContainer {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    justify-self: center;
}

.numberStepperContainer button {
    background: none;
    border: none;
    font-size: 32px;
    color: white;
    cursor: pointer;
}

.numberStepperContainer input,
.numberStepperContainer span {
    width: 120px;
    color: #4F1172;
    background: none;
    border-radius: 4px;
    border: 2px solid white;
    text-align: center;
    font-size: 32px;
}

.numberStepperContainer input:focus {
    outline: none;
}
