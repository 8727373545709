.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* O el tamaño que prefieras */
}

.spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    border-top: 5px solid #5a2b9a;
    width: 30px;
    height: 30px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
